<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413.69 461.24">
    <path d="M24.87,116.9l65.19,37.63a8.27,8.27,0,0,0,8.25,0L168,114.32a8.26,8.26,0,0,0,0-14.3L97.82,59.52a8.29,8.29,0,0,0-8.26,0L26.42,96l-1.57.9a11.57,11.57,0,0,0,0,20Zm186,7.91a8.29,8.29,0,0,0-8.26,0L133,165a8.26,8.26,0,0,0-3,11.28,8.37,8.37,0,0,0,3,3l71.2,41.1a5,5,0,0,0,5.09,0l71.23-41.12a8.26,8.26,0,0,0,0-14.3ZM132.5,49,201,88.58a11.58,11.58,0,0,0,11.56,0L281.09,49a8.26,8.26,0,0,0,0-14.3L233.24,7.08a52.85,52.85,0,0,0-52.85,0l-47.9,27.66a8.25,8.25,0,0,0,0,14.3Zm113.08,65.28,69.63,40.19a8.22,8.22,0,0,0,8.25,0l65.24-37.64c9.49-5.49,9.51-14.49,0-20L387.21,96,324,59.5a8.27,8.27,0,0,0-8.25,0L245.58,100a8.26,8.26,0,0,0-3,11.28A8.15,8.15,0,0,0,245.58,114.28Zm160.38,23-183.5,106a4.82,4.82,0,0,0-2.43,4.18l-.08,129C220,384.47,214,391,206.77,391a12.62,12.62,0,0,1-9.32-4.3,15.2,15.2,0,0,1-3.84-10.3l.08-128.56a5.35,5.35,0,0,0-2.64-4.61L7.55,137.3A5,5,0,0,0,0,141.65V319.51a52.83,52.83,0,0,0,26.42,45.76l154,88.88a52.81,52.81,0,0,0,52.85,0l154-88.89a52.81,52.81,0,0,0,26.42-45.75V141.73a5.12,5.12,0,0,0-5.12-5.1h0a5,5,0,0,0-2.56.68Z"
          style="fill-rule:evenodd"/>
</svg>
</template>

<script>
export default {
  data: () => ({
    publicPath: process.env.BASE_URL
  })
}
</script>
