export default [
  /** ********************************* DASHBOARD *********************************** **/
  {
    url: { name: 'dashboard' },
    name: 'Dashboard',
    icon: 'HomeIcon'
  },

  /** ********************************* MASTER *********************************** **/
  {
    url: null,
    name: 'Master Data',
    icon: 'DatabaseIcon',
    submenu: [
      {
        url: null,
        name: 'Other',
        icon: 'Share2Icon',
        submenu: [
          {
            url: { name: 'master.coa' },
            name: 'COA',
            slug: 'master.coa.setProyek',
            can: ['view_coa']
          },
          {
            url: { name: 'master.caraBayar' },
            name: 'Cara Bayar',
            slug: 'master.caraBayar.setProyek',
            can: ['view_cara_bayar']
          },
          {
            url: { name: 'master.bankKpr' },
            name: 'Bank KPR',
            slug: 'master.bankKpr',
            can: ['view_bank_kpr']
          },
          {
            url: { name: 'master.sumberInfo' },
            name: 'Sumber Info',
            can: ['view_sumber_info']
          },
          {
            url: { name: 'master.mediaPlatform' },
            name: 'Media Platform',
            can: ['view_media_platform']
          },
          {
            url: { name: 'master.currency' },
            name: 'Currency',
            can: ['view_currency']
          },
          {
            url: { name: 'master.hadiah' },
            name: 'Hadiah',
            can: ['view_hadiah']
          }
        ]
      },
      {
        url: null,
        name: 'Person',
        icon: 'UserIcon',
        submenu: [
          {
            url: { name: 'master.staf' },
            name: 'Staf',
            slug: 'master.staf.setProyek',
            can: ['view_staf']
          },
          {
            url: { name: 'master.jenisRekanan' },
            name: 'Jenis Rekanan',
            can: ['view_jenis_rekanan']
          },
          {
            url: { name: 'master.rekanan' },
            name: 'Rekanan',
            slug: 'master.rekanan.setProyek',
            can: ['view_rekanan']
          },
          {
            url: { name: 'master.customer' },
            name: 'Customer',
            can: ['view_customer']
          },
          {
            url: { name: 'master.profesi' },
            name: 'Profesi Customer',
            can: ['view_profesi_customer']
          }
        ]
      },
      {
        url: null,
        name: 'Item',
        icon: 'BookmarkIcon',
        submenu: [
          {
            url: { name: 'master.itemRab' },
            name: 'Item RAB',
            can: ['view_item_rab']
          },
          {
            url: { name: 'master.itemKpr' },
            name: 'Item KPR',
            slug: 'master.itemKpr',
            can: ['view_item_kpr']
          },
          {
            url: { name: 'master.itemIpl' },
            name: 'Item IPL',
            slug: 'master.itemIpl',
            can: ['view_item_ipl']
          },
          {
            url: { name: 'master.itemSales' },
            name: 'Item Jasa Estate',
            slug: 'master.itemSales',
            can: ['view_item_sales']
          },
          {
            url: { name: 'master.itemKebersihan' },
            name: 'Item Kebersihan',
            slug: 'master.itemKebersihan',
            can: ['view_item_kebersihan']
          },
          {
            url: { name: 'master.itemPengadaan' },
            name: 'Item Pengadaan',
            can: ['view_item_pengadaan']
          },
          {
            url: { name: 'master.itemPerijinanWarga' },
            name: 'Item Perijinan Warga',
            can: ['view_item_perijinan_warga']
          },
          {
            url: { name: 'master.itemGroup1' },
            name: 'Item Group 1',
            can: ['view_item_group_1']
          },
          {
            url: { name: 'master.itemGroup2' },
            name: 'Item Group 2',
            can: ['view_item_group_2']
          },
          {
            url: { name: 'master.itemGroup3' },
            name: 'Item Group 3',
            can: ['view_item_group_3']
          },
          {
            url: { name: 'master.itemGroup4' },
            name: 'Item Group 4',
            can: ['view_item_group_4']
          },
          {
            url: { name: 'master.itemProgressPekerjaan' },
            name: 'Item Progress Pekerjaan',
            slug: 'master.itemProgressPekerjaan.setBobot',
            can: ['view_item_progress_pekerjaan']
          },
          {
            url: { name: 'master.itemDokumenCustomer' },
            name: 'Item Dokumen Customer',
            slug: 'master.itemDokumenCustomer',
            can: ['view_item_dokumen_customer']
          },
          {
            url: { name: 'master.itemDokumenProyek' },
            name: 'Item Dokumen Proyek',
            can: ['view_item_dokumen_proyek']
          }
        ]
      },
      {
        url: null,
        name: 'Properti',
        icon: 'MapIcon',
        submenu: [
          {
            url: { name: 'master.company' },
            name: 'Company',
            can: ['view_company']
          },
          {
            url: { name: 'master.proyek' },
            name: 'Proyek',
            can: ['view_proyek']
          },
          {
            url: { name: 'master.propertiKategori' },
            name: 'Properti Kategori',
            can: ['view_properti_kategori']
          },
          {
            url: { name: 'master.propertiTipe' },
            name: 'Properti Tipe',
            can: ['view_properti_tipe']
          },
          {
            url: { name: 'master.cluster' },
            name: 'Properti Cluster',
            can: ['view_properti_cluster']
          },
          {
            url: { name: 'master.propertiUnit' },
            name: 'Properti Unit',
            slug: 'master.propertiUnit',
            can: ['view_properti_unit']
          }
        ]
      }
    ]
  },

  /** ********************************* PROYEK *********************************** **/
  {
    url: null,
    name: 'Proyek',
    icon: 'LayersIcon',
    submenu: [
      {
        url: { name: 'proyek.siteplan' },
        name: 'Site Plan',
        can: ['view_siteplan']
      },
      {
        url: { name: 'proyek.rabProyek' },
        name: 'RAB Proyek',
        can: ['view_rab_proyek']
      },
      {
        url: { name: 'proyek.rabUnit' },
        name: 'RAB Unit',
        can: ['view_rab_unit']
      },
      {
        url: { name: 'proyek.spk' },
        name: 'SPP',
        slug: 'proyek.spk',
        can: ['view_spp']
      },
      {
        url: { name: 'proyek.progressProyek' },
        name: 'Progress Proyek',
        slug: 'proyek.progressProyek',
        can: ['view_progress_proyek']
      },
      {
        url: { name: 'proyek.progressUnit' },
        name: 'Progress Unit',
        slug: 'proyek.progressUnit',
        can: ['view_progress_unit']
      },
      {
        url: { name: 'proyek.spkRequestPayment' },
        name: 'SPP Req. Payment',
        slug: 'proyek.spkRequestPayment',
        can: ['view_spp_request_payment']
      },
      {
        url: { name: 'proyek.stk' },
        name: 'STK (Kontraktor)',
        can: ['view_stk']
      },
      {
        url: { name: 'proyek.po' },
        name: 'PO',
        slug: 'proyek.po',
        can: ['view_purchase_order']
      },
      {
        url: { name: 'proyek.progressDokumenProyek' },
        name: 'Progress Dok. Proyek',
        can: ['view_progress_dokumen_proyek']
      },
      {
        url: { name: 'proyek.progressDokumenUnit' },
        name: 'Progress Dok. Unit',
        can: ['view_progress_dokumen_unit']
      }
    ]
  },

  /** ********************************* MARKETING *********************************** **/
  {
    url: null,
    name: 'Marketing',
    icon: 'BookOpenIcon',
    submenu: [
      {
        url: { name: 'marketing.media' },
        slug: 'marketing.media',
        name: 'Media',
        can: ['view_media']
      },
      {
        url: { name: 'marketing.pengajuanMedia' },
        slug: 'marketing.pengajuanMedia',
        name: 'Pengajuan Media',
        can: ['view_pengajuan_media']
      },
      {
        url: { name: 'marketing.customerProspek' },
        name: 'Customer Prospek',
        can: ['view_customer_prospek']
      },
      {
        url: { name: 'marketing.aktifitasMarketing' },
        name: 'Aktifitas Marketing',
        slug: 'marketing.aktifitasMarketing',
        can: ['view_aktivitas_marketing']
      },
      {
        url: { name: 'marketing.booking' },
        name: 'Booking',
        slug: 'marketing.booking',
        can: ['view_booking']
      },
      {
        url: { name: 'marketing.kontrak' },
        name: 'Kontrak',
        slug: 'marketing.kontrak',
        can: ['view_kontrak']
      },
      {
        url: { name: 'marketing.serahTerimaCustomer' },
        name: 'STC (Customer)',
        slug: 'marketing.serahTerimaCustomer',
        can: ['view_stc']
      },
      {
        url: { name: 'marketing.komisi' },
        name: 'Komisi',
        slug: 'marketing.komisi',
        can: ['view_komisi']
      },
      {
        url: null,
        name: 'Marketing Fee',
        slug: 'marketing.marketingFee',
        submenu: [
          {
            url: { name: 'marketing.marketingFee.setDealer' },
            name: 'Set Dealer',
            can: ['view_dealer']
          },
          {
            url: { name: 'marketing.marketingFee.setFee' },
            name: 'Set Fee',
            can: ['view_fee']
          }
        ]
      }
    ]
  },

  /** ********************************* ESTATE *********************************** **/
  {
    url: null,
    name: 'Estate',
    icon: 'FlagIcon',
    submenu: [
      {
        url: { name: 'estate.ipl' },
        name: 'Penerimaan IPL',
        can: ['view_penerimaan_ipl_termin']
      },
      {
        url: { name: 'estate.komplainWarga' },
        name: 'Komplain Warga',
        can: ['view_komplain_warga']
      },
      {
        url: { name: 'estate.perijinanWarga' },
        name: 'Perijinan Warga',
        can: ['view_perijinan_warga']
      },
      {
        url: { name: 'estate.keamanan' },
        name: 'Keamanan',
        slug: 'estate.keamanan',
        can: ['view_keamanan']
      },
      {
        url: { name: 'estate.kebersihan' },
        name: 'Kebersihan',
        slug: 'estate.kebersihan',
        can: ['view_kebersihan']
      },
      {
        url: { name: 'estate.pos' },
        name: 'POS',
        slug: 'estate.pos',
        can: ['view_pos']
      }
    ]
  },

  /** ********************************* PROCUREMENT *********************************** **/
  {
    url: null,
    name: 'Procurement',
    icon: 'PackageIcon',
    submenu: [
      {
        url: { name: 'procurement.pengadaan' },
        slug: 'procurement.pengadaan',
        name: 'Purchase Request',
        can: ['view_purchase_request']
      },
      {
        url: { name: 'procurement.reimburse' },
        name: 'Reimbursement',
        can: ['view_reimburse']
      },
      {
        url: { name: 'procurement.cashAdvance' },
        name: 'Cash Advance',
        can: ['view_cash_advance']
      },
      {
        url: { name: 'procurement.dlk' },
        name: 'DLK',
        can: ['view_dlk']
      }
    ]
  },

  /** ********************************** APPROVALS ************************************ **/
  {
    url: null,
    name: 'Approvals',
    slug: 'approvals',
    icon: 'CheckSquareIcon',
    submenu: [
      {
        url: { name: 'approvals.purchaseRequestApproval' },
        name: 'A. Purchase Request',
        slug: 'approvals.purchaseRequestApproval',
        can: ['view_approval_purchase_request']
      },
      {
        url: { name: 'approvals.poKontrakApproval' },
        name: 'A. PO Kontrak',
        slug: 'approvals.poKontrakApproval',
        can: ['view_approval_po_kontrak']
      },
      {
        url: { name: 'approvals.poTerminApproval' },
        name: 'A. PO Termin',
        slug: 'approvals.poTerminApproval',
        can: ['view_approval_po_termin']
      },
      {
        url: { name: 'approvals.spkKontrakApproval' },
        name: 'A. SPP Kontrak',
        slug: 'approvals.spkKontrakApproval',
        can: ['view_approval_spp_kontrak']
      },
      {
        url: { name: 'approvals.spkProgressApproval' },
        name: 'A. SPP Progress',
        slug: 'approvals.spkProgressApproval',
        can: ['view_approval_spp_progress']
      },
      {
        url: { name: 'approvals.spkTerminApproval' },
        name: 'A. SPP Termin',
        slug: 'approvals.spkTerminApproval',
        can: ['view_approval_spp_termin']
      },
      {
        url: { name: 'approvals.spkAdjustApproval' },
        name: 'A. SPP Adjust',
        slug: 'approvals.spkAdjustApproval',
        can: ['view_approval_spp_adjust']
      },
      {
        url: { name: 'approvals.stkApproval' },
        name: 'A. STK (Kontraktor)',
        slug: 'approvals.stkApproval',
        can: ['view_approval_stk']
      },
      {
        url: { name: 'approvals.stcApproval' },
        name: 'A. STC (Customer)',
        slug: 'approvals.stcApproval',
        can: ['view_approval_stc']
      },
      {
        url: { name: 'approvals.skupApproval' },
        name: 'A. SKUP',
        slug: 'approvals.skupApproval',
        can: ['view_approval_skup']
      },
      {
        url: { name: 'approvals.paymentRequestApproval' },
        name: 'A. Payment Request',
        slug: 'approvals.paymentRequestApproval',
        can: ['view_approval_payment_request']
      },
      {
        url: { name: 'approvals.komisiApproval' },
        name: 'A. Komisi',
        slug: 'approvals.komisiApproval',
        can: ['view_approval_komisi']
      },
      {
        url: { name: 'approvals.reimburseApproval' },
        name: 'A. Reimburse',
        slug: 'approvals.reimburseApproval',
        can: ['view_approval_reimburse']
      },
      {
        url: { name: 'approvals.cashAdvanceApproval' },
        name: 'A. Cash Advance',
        slug: 'approvals.cashAdvanceApproval',
        can: ['view_approval_cash_advance']
      },
      {
        url: { name: 'approvals.perijinanWargaApproval' },
        name: 'A. Perijinan Warga',
        slug: 'approvals.perijinanWargaApproval',
        can: ['view_approval_perijinan_warga']
      },
      {
        url: { name: 'approvals.pengajuanMediaApproval' },
        name: 'A. Pengajuan Media',
        slug: 'approvals.pengajuanMediaApproval',
        can: ['view_approval_pengajuan_media']
      }
    ]
  },

  /** ********************************* ACCOUNTING *********************************** **/
  {
    url: null,
    name: 'Accounting',
    icon: 'DollarSignIcon',
    submenu: [
      {
        url: { name: 'accounting.postingTransaksi' },
        name: 'Posting Transaksi',
        slug: 'accounting.postingTransaksi',
        can: ['view_posting_transaksi']
      },
      {
        url: { name: 'accounting.generalJurnal' },
        name: 'General Jurnal',
        slug: 'accounting.generalJurnal',
        can: ['view_general_jurnal']
      },
      {
        url: { name: 'accounting.generalLedger' },
        name: 'General Ledger',
        slug: 'accounting.generalLedger',
        can: ['view_general_ledger']
      },
      {
        url: { name: 'accounting.pengakuanPendapatan' },
        name: 'Peng. Pendapatan',
        slug: 'accounting.pengakuanPendapatan',
        can: ['view_pengakuan_pendapatan']
      },
      {
        url: { name: 'accounting.prepaid' },
        name: 'Prepaid',
        slug: 'accounting.prepaid',
        can: ['view_prepaid']
      },
      {
        url: { name: 'accounting.fixedAsset' },
        name: 'Fixed Asset',
        slug: 'accounting.fixedAsset',
        can: ['view_fixed_asset']
      },
      {
        url: null,
        name: 'Setup',
        submenu: [
          {
            url: { name: 'accounting.accountBudgeting' },
            name: 'Account Budgeting',
            slug: 'accounting.accountBudgeting',
            can: ['view_account_budgeting']
          },
          {
            url: { name: 'accounting.setupPrepaid' },
            name: 'Setup Prepaid',
            slug: 'accounting.setupPrepaid',
            can: ['view_setup_prepaid']
          },
          {
            url: { name: 'accounting.setupFixedAsset' },
            name: 'Setup Fixed Asset',
            slug: 'accounting.setupFixedAsset',
            can: ['view_setup_fixed_asset']
          },
          {
            url: { name: 'accounting.setupReport' },
            name: 'Setup Report',
            slug: 'accounting.setupReport',
            can: ['view_setup_report']
          }
        ]
      },
      {
        url: null,
        name: 'Account Payable',
        submenu: [
          {
            url: { name: 'accounting.ap' },
            name: 'AP',
            slug: 'accounting.ap',
            can: ['view_ap']
          },
          {
            url: { name: 'accounting.apOther' },
            name: 'AP Other',
            slug: 'accounting.apOther',
            can: ['view_ap_other']
          },
          {
            url: { name: 'accounting.apSaldoAwal' },
            name: 'AP Saldo Awal',
            slug: 'accounting.apSaldoAwal',
            can: ['view_ap_saldo_awal']
          },
          {
            url: { name: 'accounting.apAging' },
            name: 'AP Aging',
            slug: 'accounting.apAging',
            can: ['view_ap_aging']
          }
        ]
      },
      {
        url: null,
        name: 'Account Receivable',
        submenu: [
          {
            url: { name: 'accounting.ar' },
            name: 'AR',
            slug: 'accounting.ar',
            can: ['view_ar']
          },
          {
            url: { name: 'accounting.arOther' },
            name: 'AR Other',
            slug: 'accounting.arOther',
            can: ['view_ar_other']
          },
          {
            url: { name: 'accounting.arSaldoAwal' },
            name: 'AR Saldo Awal',
            slug: 'accounting.arSaldoAwal',
            can: ['view_ar_saldo_awal']
          },
          {
            url: { name: 'accounting.arAging' },
            name: 'AR Aging',
            slug: 'accounting.arAging',
            can: ['view_ar_aging']
          }
        ]
      }
    ]
  },

  /** ********************************* REPORTS *********************************** **/
  {
    url: { name: 'report' },
    name: 'Reports',
    icon: 'BarChart2Icon',
    target: '_blank',
    can: ['view_report']
  },

  /** ********************************* GENERAL *********************************** **/
  {
    url: null,
    name: 'General',
    icon: 'SlidersIcon',
    submenu: [
      {
        url: { name: 'general.accessControl' },
        name: 'Access Control',
        slug: 'general.accessControl',
        can: ['view_access_control']
      },
      {
        url: { name: 'general.systemParameters' },
        name: 'System Parameters',
        slug: 'general.systemParameters',
        can: ['view_system_parameters']
      },
      {
        url: { name: 'general.systemApprovals' },
        name: 'System Approvals',
        slug: 'general.systemApprovals',
        can: ['view_system_approvals']
      },
      {
        url: { name: 'general.activityLog' },
        name: 'Activity Log',
        slug: 'general.activityLog',
        can: ['view_activity_log']
      }
    ]
  }

]
